<template>
    <div>
        <slot name="image">
            <z-picture img="drivers-1.jpg" alt="shop andares" className="w-100" />
        </slot>
        <b-container class="py-5">
            <b-row>
                <b-col md="5">
                    <h2 class="z-text-blue z-text-small text-md-left mb-3">
                        Mensajeros
                    </h2>
                    <p class="z-text-bigger text-md-left">
                        Obtén más ingresos con los horarios que tú elijas
                    </p>
                </b-col>
            </b-row>
            <b-row class="text-md-left">
                <b-col xs="6" md="4">
                    <div class="perk">
                        <div class="check">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                        </div>
                        <p class="z-text-medium">
                            Horarios flexibles
                        </p>
                    </div>
                </b-col>
                <b-col xs="6" md="4">
                    <div class="perk">
                        <div class="check">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                        </div>
                        <p class="z-text-medium">
                            Recibe tus ingresos más bonos y propinas
                        </p>
                    </div>
                </b-col>
                <b-col xs="6" md="4">
                    <div class="perk">
                        <div class="check">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                        </div>
                        <p class="z-text-medium">
                            Crea contactos y amigos nuevos
                        </p>
                    </div>
                </b-col>
                <b-col xs="6" md="4">
                    <div class="perk">
                        <div class="check">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                        </div>
                        <p class="z-text-medium">
                            Zonas verificadas y horarios seguros
                        </p>
                    </div>
                </b-col>
                <b-col xs="6" md="4">
                    <div class="perk">
                        <div class="check">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                        </div>
                        <p class="z-text-medium">
                            Descuentos con empresas aliadas
                        </p>
                    </div>
                </b-col>
                <b-col xs="12" md="3">
                    <div class="perk">
                        <div class="check">
                            <img style="opacity: 0" src="@/assets/icons/ic-check.svg" alt="check" />
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ZPicture from "./ZPicture.vue";
export default {
    components: { ZPicture },
    name: "DriverPerks",
    props: {
        imgName: {
            type: String,
            default: "drivers-1.jpg"
        }
    }
};
</script>

<style lang="scss">
.perk {
    display: flex;
    text-align: left;

    .check {
        padding-right: 1em;
    }
}
</style>
