<template>
    <div>
        <business-sign-up-hero class="z-background-light-grey">
            <picture>
                <source srcset="@/assets/img/hero.webp" type="image/webp" />
                <source srcset="@/assets/img/hero.jpg" type="image/jpeg" />
                <img class="img-fluid" src="@/assets/img/hero.jpg" alt="zubut mensajero" fluid />
            </picture>
        </business-sign-up-hero>
        <router-link class="driver-register-main-container" :to="{ name: 'para-mensajeros' }">
            <b-row class="z-background-dark text-light driver-register-container text-md-left">
                <b-col sm="12" md="7" class="order-md-11 driver-register-message">
                    <div class="driver-register">
                        <p class="z-text-light z-text-bold text-uppercase z-text-smaller">
                            ¿Cuentas con los requisitos?
                        </p>
                        <p class="z-text-bigger" style="margin: 0px;">
                            Conviértete en mensajero
                        </p>
                    </div>
                </b-col>
                <b-col sm="12" md="1" class="order-md-12 no-gutters d-none d-md-block">
                    <img
                        style="padding: 1em;"
                        src="@/assets/icons/down-arrow.svg"
                        alt="arrow right"
                        width="61"
                        height="45"
                    />
                </b-col>
                <b-col sm="12" md="4" class="requirement-imgs order-md-1">
                    <img
                        src="@/assets/img/avatar-android.png"
                        alt="android"
                        width="76"
                        height="76"
                    />
                    <img
                        src="@/assets/img/avatar-licencia.png"
                        alt="licecia"
                        width="76"
                        height="76"
                    />
                    <img src="@/assets/img/avatar-motor.png" alt="motor" width="76" height="76" />
                </b-col>
            </b-row>
        </router-link>
        <b-container>
            <b-row class="send-it-with-zubut">
                <b-col style="padding: 0px;">
                    <section class="text-md-left send-it-with-zubut-section">
                        <div class="image-message-1">
                            <img
                                class="moto"
                                src="@/assets/img/ic-moto.png"
                                alt="zubut web app"
                                width="64p"
                                height="64p"
                            />
                            <span class="z-text-blue z-text-bold">
                                #MándaloConZubut
                            </span>
                            <p class="z-text-bigger">
                                Somos la plataforma de envíos que facilita la logística de tu
                                <b>empresa</b>, <b>restaurante</b>, o <b>eCommerce</b> a nivel
                                local.
                            </p>
                        </div>
                    </section>
                </b-col>
            </b-row>
            <business-type-menu />
            <section>
                <b-row>
                    <b-col>
                        <section class="text-md-left">
                            <p class="z-text-blue z-text-small">
                                ¿Cómo funciona?
                            </p>
                            <p class="z-text-bigger">
                                Tu producto está a 3 clics de distancia de tus clientes.
                            </p>
                            <div id="landing-web-app-image">
                                <z-picture
                                    :img="howItWorksImage"
                                    alt="zubut web app"
                                    className="w-100"
                                />
                            </div>
                        </section>
                        <section>
                            <b-row class="how-it-works-buttons-section justify-content-center">
                                <!-- <b-col sm="12" md="3">
                                    <z-button
                                        variant="secondary"
                                        @click="howItWorksImage = screenImages.quote"
                                    >
                                        Cotiza un envío
                                    </z-button>
                                </b-col>
                                <b-col sm="12" md="3">
                                    <z-button
                                        variant="secondary"
                                        @click="howItWorksImage = screenImages.customize"
                                    >
                                        Personaliza tu envío
                                    </z-button>
                                </b-col>
                                <b-col sm="12" md="3">
                                    <z-button
                                        variant="secondary"
                                        @click="howItWorksImage = screenImages.schedule"
                                    >
                                        Programa un envío
                                    </z-button>
                                </b-col> -->
                                <b-col sm="12" md="3">
                                    <router-link
                                        :to="{ name: 'registrar-empresa' }"
                                        @click.native="handleClick"
                                    >
                                        <z-button>
                                            ¡Haz tu primer envío!
                                        </z-button>
                                    </router-link>
                                </b-col>
                            </b-row>
                        </section>
                    </b-col>
                </b-row>
            </section>
        </b-container>
        <hr />
        <zone-coverage />
        <hr class="m-0" />
        <b-container fluid class="no-gutters px-0">
            <estimate-cost class="py-5" />
        </b-container>
        <hr class="m-0" />
        <b-container>
            <a class="anchor" id="clients" href="#"></a>
            <client-testimonials />
        </b-container>
        <b-container fluid class="z-background-dark text-light no-gutters px-0">
            <driver-perks />
        </b-container>
        <b-container class="mb-5">
            <request-info />
            <press-bar :show-pin="true" />
        </b-container>
    </div>
</template>

<script>
import BusinessTypeMenu from "@/components/BusinessTypeMenu";
import ClientTestimonials from "@/components/ClientTestimonials";
import DriverPerks from "@/components/DriverPerks";
import EstimateCost from "@/components/EstimateCost";
import BusinessSignUpHero from "@/components/BusinessSignUpHero";
import PressBar from "@/components/PressBar";
import RequestInfo from "@/components/RequestInfo";
import ZoneCoverage from "@/components/ZoneCoverage";
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";
import ZPicture from "@/components/ZPicture.vue";

export default {
    name: "Home",
    components: {
        BusinessSignUpHero,
        BusinessTypeMenu,
        ClientTestimonials,
        DriverPerks,
        EstimateCost,
        PressBar,
        RequestInfo,
        ZoneCoverage,
        ZPicture
    },
    data() {
        return {
            howItWorksImage: "cotiza-screen.jpg",
            screenImages: {
                quote: require("@/assets/img/cotiza-screen.jpg"),
                customize: require("@/assets/img/personaliza-screen.jpg"),
                schedule: require("@/assets/img/agenda-screen.jpg")
            }
        };
    },
    methods: {
        handleClick() {
            trackActionWithAnalytics.facebook("Lead", "haz tu primer envio");
            trackActionWithAnalytics.google("link", "haz tu primer envio");
        }
    }
};
</script>

<style lang="scss" scoped>
.row.send-it-with-zubut {
    margin: 0em -2em;
}

section {
    &:not(.send-it-with-zubut-section) {
        padding: 2em 0em;
    }
}

.driver-register-main-container:hover {
    text-decoration: none;
}

.driver-register-container {
    border-radius: 2px;
    .driver-register {
        padding: 1em;
    }

    .requirement-imgs {
        display: flex;
        margin-bottom: 2em;
        justify-content: center;
        align-items: center;
        img {
            width: 76px;
            margin-left: -20px;
        }
    }
}

.image-message-1 {
    padding: 2em;
    background-image: url("../assets/img/banner-main.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .moto {
        position: absolute;
        bottom: -30px;
        left: 30px;
        transform: rotate(90deg);
    }
}

#landing-web-app-image {
    text-align: center;

    img {
        width: 100%;
        max-width: 780px;
        box-shadow: 0 2px 5px 1px rgba(164, 201, 241, 0.55);
    }
}

.how-it-works-buttons-section {
    padding: 0em 1em;
    .z-button {
        margin-bottom: 1em;
    }
}

.zone-coverage {
    padding: 1em;

    .coverage-type {
        font-size: 24px;
    }

    .coverage-time {
        font-size: 15px;
        color: #8190a1;
        font-weight: 600;
    }

    .coverage-days {
        font-size: 14px;
    }

    .zone-image-container {
        padding: 1em;
        img {
            width: 100%;
            max-width: 440px;
        }
    }
}

@media only screen and (min-width: 576px) {
    section {
        padding: 4em 0em;
    }
}

@media only screen and (min-width: 768px) {
    section {
        padding: 4em 0em;
    }

    .image-message-1 {
        padding: 6em 20em 6em 6em;

        .moto {
            position: absolute;
            top: 200px;
            left: -32px;
            transform: rotate(180deg);
        }
    }

    .driver-register-container {
        padding: 1em 2em;

        .driver-register-message {
            padding-right: 0px;
            .driver-register {
                padding: 1em 0em;
            }
        }

        .requirement-imgs {
            margin-bottom: 0px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .driver-register-container {
        position: absolute;
        margin-top: -60px;
        left: 40%;
        height: 134px;
        width: 624px;
        z-index: 1;
    }

    section {
        padding: 8em 0em;
    }

    .image-message-1 {
        padding: 10em 20em 10em 10em;
    }
}
</style>
